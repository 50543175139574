.InputField_MainWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.InputField_MainWrapper label {
  line-height: 1rem;
}

.InputField_FieldWrapper {
  display: flex;
  align-items: center;
  padding: 0.38rem;
  gap: 0.25rem;
}
