@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin : 0;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::-webkit-scrollbar {
  width: 10px;
  height:12px
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(80, 80, 80, 0.3);
  border-radius: 10px;
  margin-top: 10px;
    margin-bottom: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: gray;
}