.sapphire {
  color: #001848;
}

.royal_blue {
  color: #414bbe;
}
.royal_blue_shaded {
  color: rgba(65, 75, 190, 0.8);
}
.light_blue {
 color:#78C2F8
}

.light_grayish_navy {
  color: #9298a9;
}
.bali_hai {
  color: #8f9ca9;
}
.eclipse {
  color: #404040;
}
.coral_red {
  color: #ef4444;
}
.white {
  color:white;
}
.green {
  color:#8ACB1D;
}
.bg_sapphire {
  background-color: #001848;
}
.bg_coral_red {
  background-color: #ef4444;
}
.bg_alice_blue {
  background-color: #f9fafb;
}
.bg_royal_blue {
  background-color: #414bbe;
}

.bg_royal_blue_shaded {
  background-color: rgba(65, 75, 190, 0.8);
}
.clip-bottom {
  clip-path: polygon(100% 50%, 0 0, 100% 0, 50% 100%, 0 0)
}